import * as React from 'react';
import { useContext, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { articles, v1_jsonString } from 'api';
import { LanguageContext } from 'context/Language';
import { StoreContext } from 'context/Store';
import { getSlug, cacheTime } from 'utils';
import dynamic from 'next/dynamic';
import { Locale } from '~/src/types';

const Link = dynamic(() => import('components/Link'));
const Tooltip = dynamic(() => import('components/Tooltip'));
const NavigationTooltipItem = dynamic(() => import('components/NavigationTooltipItem'));
const Loader = dynamic(() => import('components/Loader'));
const LoaderInline = dynamic(() => import('components/Loader/Inline'));
const Container = dynamic(() => import('components/Grid/Container'));

interface Props {
  isMobile?: boolean;
  onClick?: () => void;
}

const Menu: React.FC<Props> = ({ isMobile, onClick }) => {
  const queryClient = useQueryClient();
  const { query, asPath: path } = useRouter();
  const { menu } = useContext(StoreContext);
  const { lang } = useContext(LanguageContext);
  const [active, setActive] = useState(0);
  const [secondaryMenu, setSecondaryMenu] = useState([]);

  const { data = [], isLoading } = useQuery(
    ['menu-childrens', { parents__in: active }],
    articles.getList,
    {
      enabled: active > 0 && !queryClient.getQueryData(['menu-childrens', { parents__in: active }]),
      cacheTime,
      select: (response) => response.results,
    },
  );

  const { data: dataSettings } = useQuery(['settings'], v1_jsonString.getList, {
    cacheTime,
  });

  React.useEffect(() => {
    if (
      query.lang &&
      ![Locale.RO, Locale.EN, Locale.RU].includes(query.lang as Locale) &&
      typeof window !== 'undefined'
    ) {
      window.location.href = `/${Locale.RO}`;
      return;
    }
  }, []);

  React.useEffect(() => {
    const data = dataSettings?.results?.find((i) => i.keyword === 'menuUrl');
    if (data) {
      try {
        setSecondaryMenu(JSON.parse(data.value));
      } catch (e) {
        console.warn(e.message);
      }
    }
  }, [dataSettings]);

  return (
    <>
      {typeof menu === 'undefined' && !isMobile ? (
        <LoaderInline className="color-white" />
      ) : (
        <Loader loading={typeof menu === 'undefined'}>
          {menu?.map(({ id, i18n, tags, children_objects }) =>
            children_objects.length && !isMobile ? (
              <Tooltip
                key={id}
                tooltip={
                  <Container>
                    <NavigationTooltipItem menu={data} loading={isLoading} />
                  </Container>
                }
                placement="bottom-start"
                trigger="hover"
                onVisibleChange={(state) => setActive(state ? id : 0)}
              >
                <Link args={{ i18n, tags }} key={id} onClick={onClick}>
                  <li className={cn({ 'active-nav-link': active === id })}>{i18n[lang]?.title}</li>
                </Link>
              </Tooltip>
            ) : (
              <Link args={{ i18n, tags }} key={id} onClick={onClick}>
                <li
                  className={cn({
                    'active-nav-link':
                      i18n[lang]?.slug === query?.slug ||
                      getSlug({ i18n: i18n, tags: tags }, lang) === path,
                  })}
                >
                  {i18n[lang]?.title}
                </li>
              </Link>
            ),
          )}
          {secondaryMenu &&
            secondaryMenu.length > 0 &&
            secondaryMenu.map((item, idx) => (
              <Link key={idx} href={item?.[lang]?.url}>
                <li>{item?.[lang]?.title}</li>
              </Link>
            ))}
        </Loader>
      )}
    </>
  );
};

export default Menu;
